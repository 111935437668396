const defaultAddress = '_id state {_id name acronym} city{_id name latitude longitude ibgeCode} cei neighborhoodName location locationNumber locationNotes postalCode isDefault updatedAt'
const defaultCompany = `_id document socialReason emailInvoice taxContributingType taxContributingCode isDefault createdAt
                        addresses {${defaultAddress}}
                        defaultAddress {${defaultAddress}}`
const defaultSign = `{ token user { fullName email receiveNews userType addresses {${defaultAddress}} defaultAddress {${defaultAddress}} companies {${defaultCompany}} defaultCompany {${defaultCompany}} } }`;

export const authenticationMutation = `mutation ($email:String!, $password:String!){
                        signIn(email: $email, password: $password) ${defaultSign}
                    }`;

export const migratePlanMutation = `mutation ($userType: String!){
                        migratePlan(userType: $userType) ${defaultSign}
                    }`;

export const refreshTokenQuery = `query { refreshToken ${defaultSign} }`;

export const signOutMutation = `mutation {
                        signOut
                    }`;
export const registerMutation = `mutation ($fullName: String!, $email:String!, $password:String!,
                        $userType:String!, $isMigratePlan:Boolean!){
                            signUp(form: {
                                fullName: $fullName,
                                email: $email,
                                password: $password,
                                userType: $userType,
                                isMigratePlan: $isMigratePlan
                            }
                        )
                        ${defaultSign}
                    }`;


export const updatePersonalDataMutation = `mutation ($document: String!, $phone: String!){
                        updateByCheckoutStepOne(form: {document: $document, phone: $phone}) {
                            document phone
                        }
                    }`;

export const updateProfileMutation = `mutation (
                        $fullName: String!,
                        $password: String!,
                        $birthDate: String,
                        $phone: String,
                        $cellphone: String,
                        $document: String,
                        $userType: String,
                        $profession: String,
                        $occupationArea: String,
                        $receiveNews: Boolean,
                        $changePassword: Boolean,
                        $newPassword: String,
                        $newPasswordConfirmation: String){

                        updateProfile(userData: {
                            fullName: $fullName,
                            password: $password,
                            birthDate: $birthDate,
                            phone: $phone,
                            cellphone: $cellphone,
                            document: $document,
                            userType: $userType,
                            profession: $profession,
                            occupationArea: $occupationArea,
                            receiveNews: $receiveNews,
                            changePassword: $changePassword,
                            newPassword: $newPassword,
                            newPasswordConfirmation: $newPasswordConfirmation 
                        }) {
                            email
                        }
                    }`;

export const completeUserProMutation = `mutation (
                        $document: String,
                        $phone: String,
                        $profession: String,
                        $occupationArea: String){

                        completeUserPro(userData: {
                            phone: $phone,
                            document: $document,
                            profession: $profession,
                            occupationArea: $occupationArea
                        }) {
                            email
                        }
                    }`;

export const saveAddressMutation = `mutation ($_id: String,
                                              $stateId: String!,
                                              $cityId: String!,
                                              $neighborhoodName: String!,
                                              $location: String!,
                                              $locationNumber: String!,
                                              $locationNotes: String,
                                              $postalCode: String!,
                                              $isDefault:Boolean,
                                              $companyDocument: String,
                                              $cei: String
                                              ){
                        saveAddress(address: {
                          _id: $_id,
                          stateId: $stateId,
                          cityId: $cityId,
                          neighborhoodName: $neighborhoodName,
                          location: $location,
                          locationNumber: $locationNumber,
                          locationNotes: $locationNotes,
                          postalCode: $postalCode,
                          isDefault: $isDefault,
                          cei: $cei
                        }, companyDocument: $companyDocument) { ${defaultAddress} }
                    }`;

export const destroyAddressMutation = `mutation ($password: String!, $addressId: String!, $companyDocument: String){
                        destroyAddress(password: $password, addressId: $addressId, companyDocument: $companyDocument) { _id }
                    }`;

export const getCompanyQuery = `query ($document: String!){
                        getCompany(document: $document) {
                            ${defaultCompany}
                        }
                    }`;

export const getAddressQuery = `query ($addressId: String!, $companyDocument: String){
                        getAddress(addressId: $addressId, companyDocument: $companyDocument) {
                            ${defaultAddress}
                        }
                    }`;

export const saveCompanyMutation = `mutation ($document: String!,
                                              $socialReason: String!,
                                              $emailInvoice: String!,
                                              $taxContributingType: String!,
                                              $taxContributingCode: String!,
                                              $isDefault: Boolean
                                              ){
                        saveCompany(company: {
                            document: $document
                            socialReason: $socialReason,
                            emailInvoice: $emailInvoice,
                            taxContributingType: $taxContributingType,
                            taxContributingCode: $taxContributingCode,
                            isDefault: $isDefault
                        }) { ${defaultCompany} }
                    }`;

export const destroyCompanyMutation = `mutation ($password: String!, $companyDocument: String!){
                        destroyCompany(password: $password, companyDocument: $companyDocument) { document }
                    }`;

export const meQuery = `query {
                        me {
                            fullName email receiveNews document phone cellphone birthDate userType profession occupationArea
                            addresses {${defaultAddress}}
                            defaultAddress {${defaultAddress}}
                            companies {
                                 ${defaultCompany}
                            }
                            defaultCompany {${defaultCompany}}
                        }
                    }`;

export const getPlanQuery = `query($email: String!) {
                        getPlan (email: $email) {
                            email, userType
                        }
                    }`;

export const forgotPasswordMutation = `mutation ($email: String!){
                        forgotPassword(email: $email)
                    }`;

export const changeForgotPasswordMutation = `mutation ($token: String!, $newPassword: String!, $confirmNewPassword: String!){
                        changeForgotPassword(token: $token, newPassword: $newPassword, confirmNewPassword: $confirmNewPassword)
                    }`;

export const checkHasEmailConfirmedQuery = `query ($email: String!){
                        checkHasEmailConfirmed(email: $email)
                    }`;

export const resendConfirmationTokenMutation = `mutation ($email: String!){
                        resendConfirmationToken(email: $email)
                    }`;

export const signUpEmailVerificationMutation = `mutation ($emailTokenVerification: String!){
                        signUpEmailVerification(emailTokenVerification: $emailTokenVerification) {
                            email
                        }
                    }`;

const defaultListAttributes = `_id name customerEmail notes buildingName buildingAddress timeMillis createdAt updatedAt totalValue totalQuantity
    items { price total quantity createdAt updatedAt product {
        _id mainName name thumbnail manufacturerName manufacturerId price stock leadTime
}}`;

export const createListMutation = `mutation ($name: String!){
                            createList(name: $name) {${defaultListAttributes} }
                        }`;

export const updateListDataMutation = `mutation ($_id: String, $name: String!, $customerEmail: String, $notes: String, $buildingName: String, $buildingAddress: String){
                            updateListData(listId: $_id, listData: {
                                name: $name,
                                customerEmail: $customerEmail,
                                notes: $notes,
                                buildingName: $buildingName,
                                buildingAddress: $buildingAddress
                            }) {${defaultListAttributes} }
                        }`;

export const updateListMutation = `mutation ($listId: String, $productId: String!, $quantity: Int!, $addOrUpdate: Boolean!){
                            updateList(listId: $listId, productId: $productId, quantity: $quantity, addOrUpdate: $addOrUpdate) {${defaultListAttributes} }
                        }`;

export const removeFromListMutation = `mutation ($listId: String!, $productId: String!){
                            removeFromList(listId: $listId, productId: $productId) {${defaultListAttributes} }
                        }`;
export const destroyListMutation = `mutation ($listId: String!){
                            destroyList(listId: $listId) {${defaultListAttributes} }
                        }`;

export const sendListMutation = `mutation ($listId: String!, $email: String!){
                            sendList(listId: $listId, email: $email) {${defaultListAttributes} }
                        }`;

export const getListQuery = `query ($listId: String!){
                            getList(listId: $listId) {${defaultListAttributes} }
                        }`;

export const checkProductsAvailabilityQuery = `query ($listId: String!, $productId: String, $quantity: Int){
                            checkProductsAvailability(listId: $listId, productId: $productId, quantity: $quantity) {_id name stock}
                        }`;

export const getListByGuestQuery = `query ($listId: String!){
                            getListByGuest(listId: $listId) {${defaultListAttributes} }
                        }`;

export const getListsQuery = `query ($listId: String, $timeMillis: Float, $limit: Int){
                            getLists(listId: $listId, timeMillis: $timeMillis, limit: $limit) {${defaultListAttributes} }
                        }`;

export const getListExcelQuery = `query ($listId: String){
                            getListExcel(listId: $listId) { base64 fileName extension}
                        }`;

export const getListPdfQuery = `query ($listId: String){
                            getListPdf(listId: $listId) { base64 fileName extension}
                        }`;
