import React, {useContext, useEffect, useRef, useState} from "react";
import TkHeader from "../../components/particles/TkHeader";
import TkDivisionsTop from "../../components/product/TkDivisionsTop";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import {smoothScroll, vibrate} from "../../utils/utils";
import {useSimpleInput} from "../../hooks/UseSimpleInput";
import Cleave from "cleave.js";
import {formatPostalCode, isBlank, isEmail} from "../../utils/string-utils";
import {animated as a, useTrail} from "react-spring";
import {TkContext} from "../../context/TkContext";
import TkSvgIcon from "../../components/particles/TkSvgIcon";
import {sendAnalyticsEvent} from "../../utils/analytics-utils";

export const tk_route_be_in_touch = "/fale-conosco";
export const tk_var_phone1 = (isFormatted = false) => {
  const phone = [
    "6",
    "7",
    "0",
    "2",
    "2",
    "4",
    "0",
    "4",
    "1",
    "5",
    "5",
    "5",
    "+",
  ]
    .reverse()
    .join("");
  if (isFormatted)
    return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, "$1 ($2) $3-$4");

  return phone;
};
export const tk_var_teky_name =
  "Teky Intermediação de Negócios em Suprimentos Empresarias LTDA";
export const tk_var_teky_document = "22193309000188";
export const tk_var_teky_address_street = "Av. Voluntários da Pátria";
export const tk_var_teky_address_street_number = "2035, sala D";
export const tk_var_teky_address_neighborhood = "Floresta";
export const tk_var_teky_address_city = "Porto Alegre";
export const tk_var_teky_address_state = "Rio Grande do Sul";
export const tk_var_teky_postal_code = "90230011";
export const tk_var_teky_address1 = `${tk_var_teky_address_street}, ${tk_var_teky_address_street_number} - ${tk_var_teky_address_neighborhood}, ${tk_var_teky_address_city} - ${tk_var_teky_address_state}, ${formatPostalCode(
  tk_var_teky_postal_code
)}`;
export const tk_var_email = [
  "r",
  "b",
  ".",
  "m",
  "o",
  "c",
  ".",
  "y",
  "k",
  "e",
  "t",
  "@",
  "o",
  "t",
  "a",
  "t",
  "n",
  "o",
  "c",
]
  .reverse()
  .join("");
export const tk_var_facebook =
  "https://www.facebook.com/Teky-Brasil-110949627268019";
export const tk_var_instagram = "https://www.instagram.com/tekybrasil/";
export const tk_var_youtube =
  "https://www.youtube.com/channel/UCX4gCATzVkBzT0M7xbPqEmg?disable_polymer=true";
export const tk_var_whatsapp = `https://wa.me/${tk_var_phone1()}?text=`;

export interface TekyAddress {
  addressCountry: string;
  addressRegion: string;
  addressLocality: string;
  postalCode: string;
  streetAddress: string;
  streetAddressNumber: string;
  streetAddressNotes?: string;
}

export const tk_var_location = (subsidiary: number = 1): TekyAddress => {
  switch (subsidiary) {
    case 1:
    default:
      return {
        addressCountry: "BR",
        addressRegion: tk_var_teky_address_state,
        addressLocality: tk_var_teky_address_city,
        postalCode: tk_var_teky_postal_code,
        streetAddress: tk_var_teky_address_street,
        streetAddressNumber: tk_var_teky_address_street_number,
      };
  }
};

interface StateBeInTouchData {
  errors: string[];
  hasErrors: boolean;
  success: boolean;
}

const TkBeInTouchView: React.FC = () => {
  const phoneRef = useRef(null);
  const { value: name, bind: bindName, setValue: setName } = useSimpleInput("");
  const {
    value: subject,
    bind: bindSubject,
    setValue: setSubject,
  } = useSimpleInput("");
  const {
    value: message,
    bind: bindMessage,
    setValue: setMessage,
  } = useSimpleInput("");
  const {
    value: email,
    bind: bindEmail,
    setValue: setEmail,
  } = useSimpleInput("");
  const [phone, setPhone] = useState();
  const [sending, setSending] = useState(false);
  const { sendBeInTouch } = useContext(TkContext);

  const initialState: StateBeInTouchData = {
    hasErrors: false,
    errors: [],
    success: false,
  };

  const [state, setState] = useState<StateBeInTouchData>({ ...initialState });

  const config = { mass: 5, tension: 2000, friction: 200 };
  const trail = useTrail(state.errors.length, {
    config,
    opacity: state.hasErrors ? 1 : 0,
    from: { opacity: 0 },
  });

  useEffect(() => smoothScroll(), []);

  useEffect(() => {
    let phoneCleave: Cleave | null;

    if (phoneRef.current) {
      phoneCleave = new Cleave(phoneRef.current || "", {
        numericOnly: true,
        blocks: [0, 2, 5, 4],
        delimiters: ["(", ") ", "-"],
        onValueChanged: ({ target }) => setPhone(target.value),
      });
    }

    return () => {
      if (phoneCleave) phoneCleave.destroy();

      phoneCleave = null;
    };
  }, [phoneRef]);

  function onSubmit(e: any) {
    e.preventDefault();

    setSending(true);

    let newState = { ...initialState };

    if (isBlank(name)) {
      newState = {
        ...newState,
        hasErrors: true,
        errors: [...newState.errors, "Informe o nome"],
      };
    }

    if (isBlank(subject)) {
      newState = {
        ...newState,
        hasErrors: true,
        errors: [...newState.errors, "Informe o assunto"],
      };
    }

    if (isBlank(message)) {
      newState = {
        ...newState,
        hasErrors: true,
        errors: [...newState.errors, "Informe a mensagem"],
      };
    }

    if (
      !isBlank(phone) &&
      !/^\d{9,11}$/.test((phone || "").replace(/\D+/g, ""))
    ) {
      newState = {
        ...newState,
        hasErrors: true,
        errors: [...newState.errors, "Telefone inválido"],
      };
    }

    if (!isBlank(email) && !isEmail(email)) {
      newState = {
        ...newState,
        hasErrors: true,
        errors: [...newState.errors, "E-mail inválido"],
      };
    }

    setState(newState);
    if (newState.hasErrors) {
      vibrate(200);
      setSending(false);
    } else {
      sendBeInTouch(name, subject, message, phone, email)
        .then((result) => {
          if (result) {
            setState({ ...initialState, success: true });

            setTimeout(() => {
              setState({ ...initialState, success: false });
            }, 4000);

            sendAnalyticsEvent("Contato", "Formulário", subject, 1);

            setName("");
            setSubject("");
            setMessage("");
            setPhone("");
            setEmail("");
          } else {
            setState({
              ...initialState,
              hasErrors: true,
              errors: [
                "Opa, aconteceu algum problema tentando enviar. Tente novamente.",
              ],
            });
          }
        })
        .catch((e) => {
          setState({
            ...initialState,
            hasErrors: true,
            errors: [
              "Opa, aconteceu algum problema tentando enviar. Tente novamente.",
            ],
          });
          console.error(e);
        })
        .finally(() => setSending(false));
    }
  }

  return (
    <>
      <TkHeadSEO
        title="Fale Conosco | Teky"
        description="Entre em contato com a Teky para tirar suas dúvidas ou dar sugestões"
      />

      <TkHeader />

      <TkDivisionsTop />

      <div className="container m-t-1rem">
        <TkBreadcrumb
          list={[
            {
              url: tk_route_be_in_touch,
              label: "Fale Conosco",
            },
          ]}
        />

        <h1>Fale Conosco</h1>

        <div className="row">
          <div className="col-md-4">
            <h2>Atendimento Teky</h2>
            <p>
              Entre em contato conosco através do email: <b>{tk_var_email}</b>
            </p>
            <p>
              Contato via WhatsApp:&nbsp;
              <b>
                <a
                  onClick={() =>
                    sendAnalyticsEvent(
                      "Telefone",
                      "Discar",
                      tk_var_phone1(true),
                      1
                    )
                  }
                  href={`tel:${tk_var_phone1()}`}
                >
                  {tk_var_phone1(true)}
                </a>
              </b>
            </p>
            <br />
            <br />

            <form onSubmit={onSubmit} className="o-h p-5px" noValidate={true}>
              <div className="form-group">
                <label htmlFor="beInTouchName">Seu nome</label>
                <input
                  type="text"
                  id="beInTouchName"
                  placeholder="Informe seu nome"
                  className="form-control form-control-sm"
                  {...bindName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="beInTouchSubject">Assunto</label>
                <input
                  type="text"
                  id="beInTouchSubject"
                  placeholder="Informe o assunto"
                  className="form-control form-control-sm"
                  {...bindSubject}
                />
              </div>
              <div className="form-group">
                <label htmlFor="beInTouchMessage">Mensagem</label>
                <textarea
                  id="beInTouchMessage"
                  style={{ minHeight: 100 }}
                  maxLength={3000}
                  placeholder="Mensagem"
                  className="form-control form-control-sm"
                  {...bindMessage}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="beInTouchPhone">Telefone (opcional)</label>
                    <input
                      type="tel"
                      ref={phoneRef}
                      value={phone}
                      id="beInTouchPhone"
                      placeholder="Telefone"
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="beInTouchEmail">E-mail (opcional)</label>
                    <input
                      type="email"
                      id="beInTouchEmail"
                      placeholder="E-mail"
                      className="form-control form-control-sm"
                      {...bindEmail}
                    />
                  </div>
                </div>
              </div>

              {trail.map((props, index) => (
                <a.div key={index} className="alert alert-danger" style={props}>
                  {state.errors[index]}
                </a.div>
              ))}

              {state.success && (
                <div className="alert alert-success">
                  Mensagem enviada com sucesso!
                </div>
              )}

              <button
                type="submit"
                disabled={sending}
                className="btn btn-sm btn-primary m-t-30px"
              >
                {sending ? (
                  <TkSvgIcon
                    className="rotate-1-seg m-r-1em"
                    icon="sync-solid"
                  />
                ) : (
                  <TkSvgIcon icon="check-solid" className="m-r-1em" />
                )}
                {sending ? (
                  <span>Enviando...</span>
                ) : (
                  <span>Enviar mensagem</span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <TkNewsLetter />
      <TkFooter />
    </>
  );
};

export default TkBeInTouchView;
